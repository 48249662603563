import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import {FaEnvelope, FaLocationArrow, FaFacebook, FaLinkedin} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import SiteImg1 from "../assets/img/component-site.png"



const TermsConditionsPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout type="privacy">
        <SEO title="Terms and Conditions" />


        <section className="contact-form-section">

          <h1>Terms and Conditions</h1>

          <div className="full-width-paragraph">
          [last updated: December 2020]<br/>

          <h3>1. Introduction</h3>

          <p>1.1. Our Platform<br/>
This page tells you the terms on which you may use our Platform, whether delivered via the Website https://www.fingersnap.it ("the Website") or the software that we provide: https://preview.fingersnap.it ("the Preview app") or https://www.fingersnap.it/api ("the API").
Please read carefully before use. By using the Platform, you accept the terms and agree to obey them. If you don't accept them, please don't use the Platform.</p>
          <p>1.2. Company details <br/>
          Fingersnap Ltd, a limited company registered in England and Wales (registered number 12768556) with its registered office at Hoghton Chambers, Hoghton Street, Southport, United Kingdom, PR9 0TB ("Fingersnap", "we" or "us"). Our VAT number is 356 8992 34. We operate the platform https://www.fingersnap.it.</p>
          <p>1.3. Contacting us<br/>
          To contact us for technical issues or support, please e-mail our customer services team at support@fingersnap.it.</p>

          <h3>2. Our contract with you</h3>

          <p>2.1. Our contract<br/> 
          These terms and conditions ("Terms") apply to: (i) the order placed by you or your Linked Account (as the case may be); and/or (ii) the contract for the supply of Services by us to you ("Contract"). They apply to the exclusion of any other terms that you seek to impose or incorporate, or which are implied by trade, custom, practice or course of dealing.</p>
          <p>2.2. These terms apply to Clients and Partners of Fingersnap<br/> 
            You should know if you are either a Client or a Partner, these terms apply to you both. A Client means anyone signing up to use the Services including customers of Partners, and who isn't a Partner. A Partner means a Fingersnap customer with reselling contract or third-party integration agreement.</p>
          <p>2.3 Users <br/>
          As a Partner, you are responsible for all Partner Users using the Services. As a Client, you are responsible for all Authorised Users using the Services.</p>
          
          <h3>3. Definitions</h3>
          <p>3.1. "Authorised Users" means any of your staff who are authorised by you to use the Services and the Documentation.</p>
          <p>3.2. "Annual Subscriber" means an annual subscriber as set out in the Plan</p>
          <p>3.3. "Business Day" means a day other than a Saturday, Sunday or public holiday in England when banks in London are open for business</p>
          <p>3.4. "Confidential Information" means any information of a confidential nature (regardless of whether or not such information is recorded in any physical, electronic or other media) concerning either you or us which is confidential, commercially sensitive and not in the public domain (whether or not marked confidential)</p>
          <p>3.5. "Control" shall be as defined in section 1124 of the Corporation Tax Act 2010, and the expression "change of Control" shall be construed accordingly</p>
          <p>3.6. "Customer Data" means the data inputted by you, Partner Users (if you are a Partner) or by us on your behalf, for the purpose of using the Services or facilitating your use of the Services</p>
          <p>3.7. "Documentation" means the document made available to you by us: (i) online via https://www.fingersnap.it  or (ii) via email; (or such other web address notified by us to you)</p>
          <p>3.8. "Fees" means the fees payable under this Contract (excluding VAT and all other relevant taxes, where applicable), as detailed by us including through our website and as part of the Order Process</p>
          <p>3.9. "Forever free" shall have the meaning given to it in clause 4.1</p>
          <p>3.9. "Free Trial" shall have the meaning given to it in clause 4.1</p>
          <p>3.10. "Demo" shall have the meaning given to it in clause 4.1</p>
          <p>3.11. "Commencement Date" shall have the meaning given to it in clause 5.4</p>
          <p>3.12. "Initial Subscription Period" means for Monthly Subscribers a one-month period and for Annual Subscribers or Annual Commitment Pay Monthly Subscribers a 12-month period, in each case starting on the Commencement Date</p>
          <p>3.13. "Monthly Subscriber" means a monthly subscriber as set out in the Plan</p>
          <p>3.14. "Partner Clients" means your clients or customers who use the Services or on whose behalf you use the Services, including any of the Staff of those clients or customers</p>
          <p>3.15. "Partner Users" means Authorised Users, and Partner Clients</p>
          <p>3.16. "Normal Business Hours" means 9.00 am to 5.00 pm local UK time, each Business Day;</p>
          <p>3.17. "Plan" shall have the meaning given to it in clause 5.1, and shall form part of the Services;</p>
          <p>3.18. "Renewal Period" shall have the meaning given to it in clause 16.1;</p>
          <p>3.19. "Services" means the online products and services provided by Fingersnap to assist with the automation of data management. This includes add-on services purchased separately (if any), unless stated otherwise in the add-on order process. Certain products and services, whether as part of a Plan or Bundle or as an Add on, will be provided to you directly by Fingersnap (or one of its subsidiaries) whereas other products and services will be provided to you by a third party and the role of Fingersnap in the provision of such products or services will be as an introducer only</p>
          <p>3.20. "Staff" means directors, partners, executives, employees, agents and/or independent contractors</p>
          <p>3.21. "Subscription Period" means the Initial Subscription Period together with any subsequent Renewal Periods.</p>

          <h3>4. Forever free, Free Trial and Demo</h3>
          <p>4.1. You are entitled to a Forever free plan of the Services, for the purposes of deciding whether or not the Services meet your requirements ("Demo"). We may also agree to provide you with access to our Services with only limited functionality so that you can monitor activities by your Partner Clients ("Free Trial"). During any agreed Demo or Free Trial, the terms in "Terms and Conditions - Forever Free, Free Trials and Demo" shall apply and this Contract shall be modified as provided in "Terms and Conditions - Forever Free, Free Trials and Demo".</p>
          <p>4.2. Following any Forever free, Free Trial or Demo, you can decide whether to place an order with us in accordance with clause 5 below.</p>


          <h3>5. Our Services</h3>
          <p>5.1. Choosing your plan<br/> 
          You shall choose a suitable product (a "Plan") for your intended use of the Services. You can downgrade or upgrade your Plan at any time, subject to clause 8. </p>
          <p>5.2. Placing your order <br/>
          Each order is an offer by you to buy the Services specified in your order subject to these Terms. You can place an order with us:  <br/> 
          (i) on the telephone by speaking to one of our customer services representatives<br/>
          (ii) by email either by sending an email confirming your order to either your customer services representative or to such other email address as we may notify you<br/>
          (iii) online by following the onscreen prompts to choose your product and place an order</p>
          <p>5.3. Correcting input errors<br/> 
          Our online order process allows you to check and amend your plan before submitting your order to us. You are responsible for ensuring that your order is complete and accurate.</p>
          <p>5.4. Accepting your order<b4/> 
          Our acceptance of your order takes place when we send an email to you to accept it ("Order Confirmation"), at which point and on which date ("Commencement Date") the Contract between you and us will come into existence. The Contract will relate only to those Services confirmed in the Order Confirmation.</p>
          <p>5.5. Features<br/>
          Additional terms and conditions may apply to certain features of our Services which are available to you as part of your Plan.</p>
          <p>5.6. Licence <br/>
          We grant you a non-exclusive and non-transferable limited right to permit the Authorised Users to access and use the Services and the Documentation during the Subscription Period in accordance with these Terms, without the right to grant sublicences ("Licence").</p>
          <p>5.7. Business use.<br/>
          The Services are for business use only. </p>
          <p>5.8. Provision of Services.<br/>
          We shall, during the Subscription Period, provide the Services and make available the Documentation to you, on and subject to the Terms.</p>
          <p>5.9. Availability<br/>
          We shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, during the Subscription Period, except for:<br/> 
           - planned maintenance carried out during the maintenance window which is usually 5.00 am to 10.00 am UK time on Sunday; and<br/>
           - unscheduled maintenance performed outside Normal Business Hours, provided that we have used reasonable endeavours to give you at least 2 Normal Business Hours notice in advance. 
          </p>
          <p>5.10. Privacy Policy<br/>
           When using the Services, you shall comply with our privacy policy https://fingersnap.it/privacy as amended from time to time.</p>
          
          <h3>6. Our obligations</h3>
          <p>6.1. We undertake that the Services will be delivered using reasonable skill and care.</p>
          <p>6.2. We provide support to all users via our website, customer support and by telephone. You accept that, although we will use reasonable endeavours to solve problems identified by you, the nature of software is such that no guarantee can be provided that any particular problem will be solved.</p>
          <p>6.3. Fingersnap does not warrant that:<br/>
          (i) your use of the Services will be uninterrupted or error-free; or<br/>
          (ii)that the Services, Documentation and/or the information obtained by you through the Services will meet your requirements.
          </p>
          <p>Fingersnap is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and you acknowledge that the Services and Documentation may be subject to limitations, delays and other problems inherent in the use of such communications networks and facilities.</p>
          
          <h3>7. Your obligations</h3>
          <p>7.1. You shall provide us with all necessary co-operation in relation to this Contract in order to provide the Services </p>
          <p>7.2. You shall without affecting your other obligations under this Contract, comply with all applicable laws and regulations with respect to your activities under this Contract </p>
          <p>7.3. You shall ensure that the Partner Users use the Services and the Documentation in accordance with this Contract and shall be responsible for any Partner User's breach of this (or their) Contract</p>
          <p>7.4. You shall obtain and shall maintain all necessary licences (excluding licences to the software used by us to deliver the Services), consents, and permissions necessary for us, our contractors and agents to perform our obligations under this Contract, including without limitation the Services</p>
          <p>7.5. You shall ensure that your network and systems comply with the relevant specifications provided by us or made available by us through our website from time to time</p>
          <p>7.6. You shall ensure that each Authorised User keeps a secure password for their use of the Services and Documentation and that each Authorised User shall keep their password confidential</p>
          <p>7.7. You shall be, to the extent permitted by law and except as otherwise expressly provided in this Contract, solely responsible for procuring, maintaining and securing your network connections and telecommunications links from your systems to our data centres, and for all problems, conditions, delays, delivery failures and all other loss or damage arising from or relating to your network connections or telecommunications links or caused by the internet.</p>
          <p>7.8. You shall not access, store, distribute or transmit any viruses, trojan horses, and other similar things or devices, or any material during the course of your use of the Services that:<br/>
          (a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive<br/>
          (b) facilitates illegal activity<br/>
          (c) depicts sexually explicit images<br/>
          (d) promotes violence<br/>
          (e) is discriminatory based on race, national origin, gender, colour, religious belief, sexual orientation or disability<br/>
          (f) is otherwise illegal or causes damage or injury to any person or property, and we reserve the right, without liability or prejudice to our other rights to you, to disable your access to any material that breaches the provisions of this clause.
          </p>
          <p>7.9. You shall not, except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the extent expressly permitted under this Contract:<br/>
          (a) attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Services and/or Documentation (as applicable) in any form or media or by any means<br/>
          (b) attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Services</p>
          <p>7.10. You shall not access all or any part of the Services and Documentation in order to build a product or service which competes with the Services and/or the Documentation; or  use the Services and/or Documentation to provide services to third parties</p>
          <p>7.11. You shall not, subject to clause 17.4, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or Documentation available to any third party (other than Partner Users if you are a Partner)</p>
          <p>7.12. You shall not attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation, other than for Partner Users or as otherwise provided for under this clause 7</p>
          <p>7.13. You shall not exceed the usage limits as set out in your agreed Plan or, if you are a Partner remove and add (or attempt to remove and add) different Partner Clients of yours, or otherwise manipulate the number of your Partner Clients, for the purpose of ensuring that you stay within the set user limits defined in your Plan.</p>
          <p>7.14. You shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Services and/or the Documentation and, in the event that you have become aware of any such unauthorised access or use, promptly notify us.</p>
          <p>7.15. The rights provided under this clause 7 are granted to you only, and shall not be considered granted to any of your subsidiaries or any holding company unless otherwise agreed by us.</p>
          <p>7.16. You indemnify us against any loss or damage we incur as a result of your breach of this clause 7 or any other term of this Agreement. </p>
          
          <h3>8. Changes</h3>
          <p>8.1. Services<br/>
          We reserve the right to amend any aspect of the Services from time to time if required by any applicable statutory or regulatory requirement or to improve the Services. Any such improvement will not materially adversely affect the nature or performance of the Services.</p>
          <p>8.2. Plan<br/>
          We reserve the right to change your Plan from time to time if we decide, in our sole discretion, that your chosen Plan is no longer suitable. Subject to clause 9.6, you can also change your Plan at any time by speaking to one of our customer services representatives. Any change to your Plan will automatically terminate this Contract, in accordance with clause 16.3(ii), which will be superseded by another order confirmation and associated contract. If you exceed the usage limits defined in your Plan we agree with you, we reserve the right to change the Plan that you are on and/or charge you for the additional use of the Services outside of your Plan in the next billing period. 
          </p>
          <p>8.3. Add-ons<br/>
          You can purchase additional complementary services from us either as part of your Plan or in addition to your Plan. Your use of the following add-on services will be subject to the separate terms and conditions.</p>
          <p>8.4. Discounts<br/>
          Where offers or discounts have been applied to your Fees by our sales team, we reserve the right to remove these offers or discounts at any time in our sole discretion. Where we decide to remove any offers or discounts, we will notify you in writing.</p>

          <h3>9. Charges and payment</h3>
          <p>9.1. In consideration of us providing the Services to you, and in accordance with your Plan, you shall pay the Fees to us in accordance with this clause 9. The Fees shall be due and payable by you as follows:
            (i) if you are an Annual Subscriber, annually in advance on the Commencement Date or relevant renewal date <br/>
            (ii) if you are a Monthly Subscriber or an Annual Commitment Pay Monthly Subscriber (see clauses 9.5 to 9.11), monthly in advance on the Commencement Date or relevant renewal date
          </p>
          <p>9.2. All amounts and Fees stated or referred to in this Contract:<br/>
          (i) shall be payable in your local currency, and
          (ii) are non-cancellable and non-refundable</p>
          <p>9.3. We reserve the right to increase the Fees, either:
          (i) where you exceed your agreed usage limits as described in clauses 8.2 and 8.3; or
          (ii) at any time (on reasonable notice which shall not in any event exceed the time periods in clause 16.1.(c)) and any such increase shall not be applied until the Renewal Period following the date of such notice (or the first Renewal Period if you are in the Initial Subscription Period when notice is given); or
          (iii) on an annual basis with effect from each anniversary of the Commencement Date in line with the percentage increase in the Retail Prices Index in the preceding 12-month period and the first such increase shall take effect on the first anniversary of the Commencement Date and shall be based on the latest available figure for the percentage increase in the Retail Prices Index as at such anniversary.
          </p>
          <p>9.4. You will be invoiced by one of the following Fingersnap entities (each, a Local Entity). The relevant Local Entity will be the entity from which you purchased your Plan, which is typically the Local Entity in your jurisdiction (or if we do not have a Local Entity in your jurisdiction, the Local Entity responsible for the jurisdiction in which you are located):
            (i) Fingersnap Ltd, a limited company registered in England and Wales (registered number 12768556) with its registered office at Hoghton Chambers, Hoghton Street, Southport, United Kingdom, PR9 0TB.
          </p>
          <p>9.5. If you are not already on an Annual Commitment Pay Monthly ("ACPM Plan"), you may upgrade your existing plan to an ACPM Plan during the Initial Subscription Period or any Renewal Period by contacting us to agree the terms of your ACPM Plan, and once agreed the ACPM Plan shall commence on your next monthly invoice date ("Upgrade Date"). Your upgraded ACPM Plan will continue for the remainder of your Initial Subscription Period or Renewal Period and then renew in accordance with clause 16.5.</p>
          <p>9.6. You are not permitted to cancel or downgrade your ACPM Plan until the end of the Initial Subscription Period or any Renewal Period (as applicable) provided you give at least 30 days' notice (in writing/email) of your intention to downgrade or cancel. Any downgrade to your ACPM Plan will take effect from the commencement of the next Renewal Period</p>
          <p>9.7. During the Initial Subscription Period and any Renewal Period, the fee for your ACPM Plan ("ACPM Plan Fee") shall be payable in full or in monthly instalments, commencing on the Commencement Date (and each anniversary thereof). During the Initial Subscription Period, and any Renewal Period, you shall be billed on a yearly basis or monthly basis, in advance.</p>
          <p>9.8. Fingersnap reserves the right to increase the ACPM Plan Fee in respect of any Renewal Period. Fingersnap shall notify you of any such increase to the ACPM Plan Fee at least 30 days prior to the commencement of the relevant Renewal Period. The increased ACPM Plan Fee shall continue to be payable in yearly or monthly instalments, commencing at the start of the relevant Renewal Period, provided that, upon receipt of notice of an increase to the ACPM Plan Fee you may request the transfer of your subscription to an alternative Plan. Any such transfer of your Plan is subject to Fingersnap's consent.</p>
          <p>9.9. If Fingersnap terminates your ACPM Plan for any reason, we may charge you an early termination fee. The early termination fee shall be no more than the fees and charges you would have paid for the remainder of the Initial Subscription Period or Renewal Period, as appropriate.</p>
          <p>9.10. If at any time during the Initial Subscription Period or any Renewal Period of your ACPM Plan you: (i) cancel (or attempt to cancel) your ACPM Plan; or (ii) fail to pay any amount due under your agreement with Fingersnap on the due date for payment and remain in default for more than 30 days after being notified in writing to make such payment, then Fingersnap may: (i) revoke all licences granted you immediately by preventing you from accessing our Services without notice; and/or (ii) terminate the agreement with immediate effect.</p>
          <p>9.11. Any changes to your Plan shall not relieve you of your obligation to pay the balance of the ACPM Plan Fee for the remainder of the Initial Subscription Period or any Renewal Period unless otherwise agreed in writing by Fingersnap.</p>

          <h3>10. Proprietary rights</h3>
          <p>10.1. You acknowledge and agree that Fingersnap and/or its licensors own all intellectual property rights in the Services and the Documentation. Except as expressly stated herein, this Contract does not grant you any rights to, under or in, any patents, copyright, database right, trade secrets, trade names, trademarks (whether registered or unregistered), or any other rights or licences in respect of the Services or the Documentation.</p>
          <p>10.2. We confirm that we have all the rights in relation to the Services and the Documentation that are necessary to grant all the rights we purport to grant under, and in accordance with, the terms of this Contract.</p>
          
          <h3>11. Consultancy services</h3>
          <p>We may, from time to time, agree to provide certain consultancy services to you, including, but not limited to, training and marketing and team productivity advice and support ("Consultancy Services"). The provision of any such Consultancy Services will be subject to a separate consultancy services agreement describing the services we agree to supply, the timetable for their performance, fees, payment terms and any related matters.</p>
          
          <h3>12. Confidentiality</h3>
          <p>12.1. Neither party shall disclose the other party's Confidential Information to a third party, except that each party may disclose the other's Confidential Information:<br/>
          (i) to its employees, officers, representatives, service providers, subcontractors or advisers who need to know such information for the purposes of carrying out the party's obligations under these Terms, provided always that such recipients shall be made aware of the confidential nature of the Confidential Information they receive and shall agree to reasonable confidentiality undertakings to protect such Confidential Information<br/>
          (ii) as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority <br/>
          (iii) as described in our privacy policy or under clause 14
          </p>
          <p>12.2. Neither party shall use the other party's Confidential Information for any purpose other than to perform its obligations under these Terms and you will on our written request or on termination of this Contract:<br/>
          (i) cease to use our Confidential Information
          (ii) as soon as reasonably practicable return to us or securely destroy (or in respect of information held electronically permanently delete (to the extent technically feasible) all of our Confidential Information in your possession.</p>
          <p>12.3. You acknowledge that details of the Services, and the results of any performance tests of the Services, constitutes our Confidential Information.</p>
          <p>12.4. We acknowledge that the Customer Data is your Confidential Information.</p>
          <p>12.5. The above provisions of this clause 12 shall survive termination of this Contract, however arising.</p>

          <h3>13. Customer data</h3>
          <p>13.1. You shall own all right, title and interest in and to all of the Customer Data and shall have sole responsibility for the legality, reliability, integrity, accuracy and quality of all such Customer Data. You consent to our use and our hosting provider's use of the Customer Data for the purposes of providing the Services.</p>
          <p>13.2. You indemnify us against any loss or damage we incur as a result of, or in connection with, any third party claim alleging that any of the Customer Data infringes or misappropriates that third party's intellectual property rights and will promptly pay us the amount of any adverse judgment or settlement together with our reasonable legal fees in relation to such a claim.</p>
          <p>13.3. In the event of any loss or damage to Customer Data, your sole and exclusive remedy against us shall be for us to use reasonable commercial endeavours to restore the lost or damaged Customer Data from the latest back-up of such Customer Data maintained by us. We shall not be responsible for any loss, destruction, alteration or disclosure of Customer Data caused by any third party (except those third parties sub-contracted by us to perform services related to Customer Data maintenance and back-up).</p>
          
          <h3>14. Data protection</h3>
          <p>14.1. In clause 14: <br/>
          (i) "Data Protection Legislation" shall mean the Data Protection Act 2018 and the General Data Protection Regulation (EU) 2016/679 (as applicable) and any other applicable laws relating to the protection of personal data and the privacy of individuals (all as amended, updated or re-enacted from time to time)<br/>
          (ii) "Personal Data", "Controller", "Processor", "Data Subject" and "Processing" shall have the same meaning as in the Data Protection Legislation.
          </p>
          <p>14.2. The parties acknowledge that we are a Processor acting on your behalf and that, for the purposes of this Contract: <br/>
          (i) the types of Personal Data are: names, contact details and other personal information uploaded or any other item added or submitted to us, and the categories of Data Subjects are: your Staff and Staff of your Partner Clients and any other individuals identified in documents uploaded by you ("Personal Data")<br/>
          (ii) the nature/purpose of the Processing is to enable us to carry out the Services (which form the subject matter of the Processing), or to provide analytics services as requested by you, and the duration of the Processing shall be the term of this Contract <br/>
          </p>
          <p>14.3. We will also act as Controller in respect of our use of personal data relating to your Staff and the Staff of your Partner Clients.</p>
          <p>14.4. We shall comply with our obligations under the Data Protection Legislation in respect of the Personal Data and shall, where we are acting as your Processor:<br/>
          (a) Process the Personal Data only to the extent, and in such manner, as is necessary for the purpose of providing the Services and in accordance with your written instructions and this clause 14<br/>
          (b) implement appropriate technical and organisational measures in accordance with the Data Protection Legislation to ensure a level of security appropriate to the risks that are presented by such Processing<br/>
          (c) ensure that any employees or other persons authorised to Process the Personal Data are subject to appropriate obligations of confidentiality<br/>
          (d) on your request and taking into account the nature of the Processing and the information available to us, assist you in ensuring compliance with our obligations under Articles 32 to 36 of the General Data Protection Regulation (EU) 2016/679 (where applicable) in respect of the Partner Personal Data<br/>
          (e) procure by way of a written contract that any subprocessors it appoints to carry out its processing obligations under this Contract will, at all times during the engagement, be subject to data processing obligations equivalent to those set out in this clause 14<br/>
          (f) not engage any third party to carry out its processing obligations under this Contract without obtaining your prior written consent, save that you hereby consent to our use of the following subprocessors: (i) members of our group; and (ii) service providers we appoint (a list of which is available on request)<br/>
          (g) notify you, as soon as reasonably practicable, about any request or complaint received from a Data Subject (without responding to that request, unless authorised to do so by you) and assist you by technical and organisational measures, insofar as possible, for the fulfilment of your obligations in respect of such requests and complaints<br/>
          (h) notify you without undue delay on becoming aware of a Personal Data breach<br/>
          (i) on your request, make available all information necessary to demonstrate our compliance with this clause 14 and on reasonable advance notice in writing otherwise permit, and contribute to, audits you (or your authorised representative) carry out with respect to the Personal Data, provided that you shall (or shall ensure your authorised representatives shall):<br/>
          - sign a non-disclosure agreement in terms acceptable to us prior to undertaking such audit<br/>
          - be accompanied by a member of our Staff at all times whilst on site during the audit<br/>
          - use your reasonable endeavours to ensure that the conduct of any such audit does not unreasonably disrupt our normal business operations; and<br/>
          - comply with our relevant IT and security policies whilst carrying out any such audit.<br/>
          (j) on termination or expiry of this Contract, destroy, delete or return (as you direct) all Personal Data and delete all existing copies of such data unless required by law to keep or store such Personal Data.
          </p>
          <p>14.5. You acknowledge that clause 14.4.(a) shall not apply to the extent that we are required by law to Process the Personal Data other than in accordance with your instructions and we acknowledge that, in such a case, we must promptly inform you of the relevant legal requirement prior to Processing (unless the law prohibits the provision of such information on important grounds of public interest).</p>
          
           
          <h3>15. Limitation of liability</h3>
          <p>15.1. Except as expressly and specifically provided in this Contract:<br/>
          (a) you assume sole responsibility for results obtained from your use of the Services and the Documentation, and for conclusions drawn from such use. We shall have no responsibility for: (i) any advice you provide to Partner Clients or any loss or decisions you or your Partner Clients make; or (ii) any advice provided to you by any Linked Accountant or any decisions you or your Linked Accountant make and shall have no liability for any loss or damage caused by such advice given or decisions made;<br/>
          (b) all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this Contract. </p>
          <p>15.2. Nothing in this Contract excludes our liability:<br/> 
          (a) for death or personal injury caused by our negligence; or<br/>
          (b) or fraud or fraudulent misrepresentation
          </p>
          <p>15.3. We shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, loss of opportunities, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses in each case however arising under this Contract.</p>
          <p>15.4. Subject to clauses 15.1, 15.2 and 15.3, our total aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of this Contract shall be limited in the Initial Subscription Period and each Renewal Period to the total Fees payable by you during that relevant period.</p>
          
          <h3>16. Term and termination</h3>
          <p>16.1. This Contract shall commence on the Commencement Date and, unless terminated earlier in accordance with this clause 16, shall continue for the Initial Subscription Period and, thereafter, the Contract shall be automatically renewed for successive periods of time (each a "Renewal Period)", see period of time applicable in the table below), unless:<br/>
          (a) if you are a Partner or a Client, either party notifies the other party of termination, in writing, with notice (see table below for applicable minimum required notice period) before the end of the Initial Subscription Period or any Renewal Period, or <br/>
          (b) if you are a Client only, you cancel the automatic renewal of the subscription to your Plan by pressing the 'Terminate account' button in the Settings tab of your account; or<br/>
          (c) the Contract is otherwise terminated in accordance with the provisions of these Terms:<br/>
          - Renewal Period: 1 month (Monthly Subscriber), 12 months (Annual Subscriber), 12 months (ACPM Subscriber)<br/>
          - Notice Period: 14 days (Monthly Subscriber), 30 days (Annual Subscriber), 30 days (ACPM Subscriber)
          </p>
          <p>16.2. We reserve the right to suspend the provision of Services and/or Documentation to you and/or Partner Users (if you are a Partner) from time to time at our sole discretion.</p>
          <p>16.3. Without affecting any other right or remedy available to us, we may terminate this Contract with immediate effect by giving written notice to you if:<br/>
          (i) there is a change of Control<br/>
          (ii) there is a change of Plan; or <br/>
          (iii) you opt to move from a Monthly Subscriber to an Annual Subscriber.
          </p>
          <p>16.4. We may terminate this Contract for any reason by providing you with at least 30 days written notice.</p>
          <p>16.5. Without affecting any other right or remedy available to it, either party may terminate this Contract with immediate effect by giving written notice to the other party if:<br/>
          16.5.1. the other party fails to pay any amount due under this Contract on the due date for payment and remains in default not less than 30 days after being notified in writing to make such payment;<br/>
          16.5.2  the other party commits a material breach of any other term of this Contract which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of 30 days after being notified in writing to do so;<br/>
          16.5.3  the other party repeatedly breaches any of the terms of this Contract in such a manner as to reasonably justify the opinion that its conduct is inconsistent with it having the intention or ability to give effect to the terms of this Contract;<br/>
          16.5.4  the other party suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they fall due or admits inability to pay its debts or is deemed unable to pay its debts within the meaning of section 123 of the Insolvency Act 1986, as if the words "it is  proved to the satisfaction of the court" did not appear in sections 123(1)(e) or 123(2) of the Insolvency Act 1986;<br/>
          16.5.5  the other party commences negotiations with all or any class of its creditors with a view to rescheduling any of its debts, or makes a proposal for or enters into any compromise or arrangement with its creditors other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party;<br/>
          16.5.6  a petition is filed, a notice is given, a resolution is passed, or an order is made, for or in connection with the winding up of that other party other than for the sole purpose of a scheme for a solvent amalgamation of that other party with one or more other companies or the solvent reconstruction of that other party;<br/>
          16.5.7  an application is made to court, or an order is made, for the appointment of an administrator, or if a notice of intention to appoint an administrator is given or if an administrator is appointed, over the other party;<br/>
          16.5.8  the holder of a qualifying floating charge over the assets of that other party has become entitled to appoint or has appointed an administrative receiver;<br/>
          16.5.9  a person becomes entitled to appoint a receiver over the assets of the other party or a receiver is appointed over the assets of the other party;<br/>
          16.5.10  a creditor or encumbrancer of the other party attaches or takes possession of, or a distress, execution, sequestration or other such process is levied or enforced on or sued against, the whole or any part of the other party’s assets and such attachment or process is not discharged within 14 days; and<br/>
          16.5.11  any event occurs, or proceeding is taken, with respect to the other party in any jurisdiction to which it is subject that has an effect equivalent or similar to any of the events mentioned in clause 16.5.4 to clause 16.5.10 (inclusive);<br/>
          </p>
          <p>16.6. On termination of this Contract for any reason:</p>
          <p>16.6.1. We  may still provide you with access to our Services and/or Documentation provided that:<br/>
          (i) you use the Services and/or Documentation strictly in accordance with the terms of this Contract (except that you shall no longer have the obligation to pay any Fees);<br/>
          (ii) you access and use the Services entirely at your own risk and therefore we shall not be liable for any claim, damages or other liability arising from or in connection with your use of the Services;<br/>
          (iii) the Services and the Documentation are provided to you on an "as is" basis; and<br/>
          (iv) we reserve the right to revoke all licences granted under this Contract immediately by preventing you from accessing our Services without notice;
          </p>
          <p>16.6.2. We may destroy or otherwise dispose of any of the Customer Data in our possession, unless we receive, no later than ten days after the date of the termination of this Contract, a written request for the delivery to you of the then most recent back-up of the Customer Data. We shall use reasonable commercial endeavours to deliver the back-up to you within 30 days of receipt of such a written request, provided that you have, at that time, paid all fees and charges outstanding at and resulting from termination (whether or not due at the date of termination). You shall pay all reasonable expenses incurred by us in returning or disposing of Customer Data; and</p>
          <p>16.6.3. Any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination, shall not be affected or prejudiced</p>
          <h3>17. General</h3>
          <p>17.1. Force majeure<br/> We shall not have any liability if we are prevented or delayed in performing our obligations under this Contract, or from carrying on our business, by any acts, events, omissions or accidence beyond our reasonable control including, without limitation, governmental action, fire, flood, insurrection, earthquake, epidemic or pandemic, power failure, riot, act of terrorism, war, explosion, embargo, strike, labour or material shortage, transportation interruption of any kind, work slowdown, failure of a utility service or telecommunications network or default of suppliers or sub-contractors.</p>
          <p>17.2. Entire agreement<br/> These Terms, together with any webpages, documents or policies incorporated into these Terms by reference, constitute the entire agreement and understanding between you and us relating to the matters contemplated by these Terms and supersedes and extinguishes all previous agreements (if any and whether in writing or not) between you and us in relation to such matters. The parties acknowledge and agree that, except as otherwise expressly provided for in these Terms, they are not entering into these Terms on the basis of, and are not relying on and have not relied on, any statement, representation, warranty or other provision (in any case whether oral, written, expressed or implied) made, given, or agreed to by any person (whether a party to these Terms or not) in relation to the subject matter of these Terms, provided that nothing in these Terms shall exclude any party from liability for fraud or fraudulent misrepresentation.</p>
          <p>17.3. Revision to terms<br/> We reserve the right to revise the terms of this Contract by updating the Terms on our website. You are advised to check the website periodically for notices concerning such revisions. Your continued use of the Services shall be deemed to constitute acceptance of any revised terms.</p>
          <p>17.4. Assignment<br/> You shall not assign, transfer, sub-contract any rights or obligations under these Terms without our prior written consent (such consent not to be unreasonably withheld) and any unauthorised assignment shall be null and void. We may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of our rights or obligations under this Contract. We shall remain liable for the performance of our subcontractors.</p>
          <p>17.5. Notices<br/> Notices to us must be sent to support@fingersnap.it if you are a Partner or a Client, or in either case to any other email address notified to you by us. We will send notices to you to the then current email address on your account.</p>
          <p>17.6. Waiver<br/> Except as otherwise provided in these Terms, a waiver of any right or remedy under these Terms or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent breach or default. A failure or delay by a party to exercise any right or remedy provided under these Terms or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under these Terms or by law shall prevent or restrict the further exercise of that or any other right or remedy.</p>
          <p>17.7. Severability<br/> If any provision or part-provision of these Terms shall be held to be invalid, illegal, void or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this clause shall not affect the validity and enforceability of the rest of these Terms.</p>
          <p>17.8. No partnership or agency<br/> Nothing in these Terms is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power)</p>
          <p>17.9. Third party rights<br/> These Terms do not confer any rights on any person or party (other than the parties to this Contract and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.</p>
          <p>17.10. Governing law<br/> This Contract and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales.</p>
          <p>17.11. Jurisdiction<br/> Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this Contract or its subject matter or formation (including non-contractual disputes or claims).</p>
          </div>
          
        </section>

        <section className="contact-form-section">
        <h1>Terms and Conditions - Forever Free, Free Trials and Demo</h1>
        <div className="full-width-paragraph">
        <p>1. We grant you a non-exclusive and non-transferable limited right to access and use the Services and the Documentation during the Demo or Free Trial. We reserve the right to revoke the licence granted under this paragraph 1, and terminate this Contract, at any time and without notice by preventing you from accessing our Services.</p> 
        <p>2. You shall use the Services and/or Documentation during a Demo or Free Trial strictly in accordance with the terms of this Contract (except that clause 5, 6.1 and 16 shall not apply and unless otherwise agreed no Fees shall be due in respect of such Demo or Free Trial under clause 9).</p>
        <p>3. You shall not attempt to circumvent any limitations placed on the Service during any Demo or Free Trial</p>
        <p>4. During the Forevere Free, Free Trial or Demo, you agree that the Services and/or Documentation are provided AS IS with no representation, guarantee or warranty of any kind as to their functionality, quality, performance, suitability or fitness for purpose.</p>
        <p>5. We shall not be liable for any claim, damages, loss or other liability arising from or in connection with your use of the Services and/or Documentation during the Forever Free, Free Trial or Demo. For the avoidance of doubt, during the Forever Free, Free Trial or Demo, this paragraph shall apply in place of clause 15.4.</p>
        </div>
        </section>


      </Layout>

    )}
  
  />
)

export default TermsConditionsPage
